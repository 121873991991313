import React from "react";
import {useStaticQuery} from 'gatsby';
import BackgroundSlider from 'gatsby-image-background-slider';
import { graphql } from 'gatsby';

function MySlider() {

    const query = useStaticQuery(graphql`
        query {
        backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
            nodes {
            relativePath
            childImageSharp {
                fluid (maxWidth: 8000, quality: 100){
                ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
            }
        }
        }
    `)

    return(

        <div className="bringFront">
            <BackgroundSlider 
            query={query}
            />
            </div>
    );
}


export default MySlider;